<!-- sign_in -->
<template>
  <div class="container b-container" id="b-container">
    <el-form
      ref="loginRulesRef"
      :model="loginFrom"
      :rules="loginRules"
      class="form"
      id="b-form"
    >
      <h2 class="form_title title">登录系统</h2>
      <el-radio-group v-model="selectLogionMethod">
        <el-radio value="密码登录">密码登录</el-radio>
        <el-radio value="验证码登录">验证码登录</el-radio>
      </el-radio-group>
      <span class="form__span">登录需要使用账户(电话)和密码</span>
      <el-form-item prop="userPhone">
        <el-input
          v-model="loginFrom.userPhone"
          style="width: 350px"
          class="form__input"
          link
          placeholder="电话"
          clearable
        />
      </el-form-item>
      <el-form-item v-if="selectLogionMethod === '密码登录'" prop="userPassword">
        <el-input
          v-model="loginFrom.userPassword"
          style="width: 350px"
          class="form__input"
          type="password"
          placeholder="密码"
          clearable
          show-password
        />
      </el-form-item>
      <el-form-item v-if="selectLogionMethod === '验证码登录'" prop="validateCode">
        <el-input
          v-model="loginFrom.validateCode"
          style="width: 350px"
          class="form__input"
          link
          placeholder="验证码"
          clearable
        ></el-input>
      </el-form-item>
      <el-button
        v-if="selectLogionMethod === '验证码登录'"
        type="success"
        link
        @click="sendValidateCode"
        :loading="isCounting"
        style="margin-left: 280px"
      >
        <template #loading>
          <div class="custom-loading">（{{ sendCodeTimeOut }}）s后过期</div>
        </template>
        <span v-if="isCounting"></span>
        <span v-else>发送验证码</span>
      </el-button>
      <a class="form__link" @click="resetPassword">忘记密码 ?</a>
      <button class="form__button button submit" @click.prevent="signin(loginRulesRef)">
        登 录
      </button>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "signinPage",
  data() {
    return {
      isCounting: false,
      sendCodeTimeOut: 180,
      selectLogionMethod: "密码登录",
      isOpen: true,
      loginFrom: {
        userPhone: "",
        userPassword: "",
        validateCode: "",
      },
      loginRules: {
        userPhone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 3, message: "账户必须大于3个字符", trigger: "blur" },
        ],
        userPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 3, message: "密码长度必须大于3个字符", trigger: "blur" },
        ],
        validateCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 5, message: "验证码必须大于5个字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    resetPassword() {
      this.$message.warning({
        message: "如果忘记密码，请采用验证码或者邮箱登录之后在个人中心页面重置密码",
        duration: 700,
      });
    },
    async signin() {
      this.$refs.loginRulesRef.validate(async (valid) => {
        if (valid) {
          var data = await this.$userutil.signin(this.selectLogionMethod, this.loginFrom);
          if (data !== undefined) {
            //存储token到本地
            this.$store.commit("SET_TOKEN", data);
            // 触发连接机制
            this.$store.dispatch("startConnectionSignalr");
            //跳转到主页
            this.$router.replace("/Index");
            this.$store.commit("defaultEditTbale");
          }
        } else {
          this.$message.error("账户密码不规则，验证不通过");
        }
      });
    },
    async sendValidateCode() {
      if (this.isCounting) return;
      if (this.loginFrom.userPhone) {
        var data = await this.$userutil.sendValidateCode(this.loginFrom.userPhone);
        if (data !== undefined) {
          // 启动倒计时
          this.isCounting = true;
          let countdown = this.sendCodeTimeOut;
          const interval = setInterval(() => {
            this.sendCodeTimeOut = countdown--;
            if (countdown <= 0) {
              clearInterval(interval);
              this.sendCodeTimeOut = 180;
              this.isCounting = false;
            }
          }, 1000);
          this.$message.success("验证码已发送，请注意查收");
        }
      } else {
        this.$message.error("请输入手机号码");
      }
    },
  },
  created() {
    this.$emitter.on("change", (newIsOpen) => {
      this.isOpen = newIsOpen;
    });
  },
  mounted() {
    this.$watch("isOpen", () => {
      //如果要处理bContainer相关逻辑，类似如下
      const bContainer = document.querySelector("#b-container");
      bContainer.classList.toggle("is-txl");
      bContainer.classList.toggle("is-z200");
    });
  },
};
</script>
<style scoped>
@import "./login.css";
</style>
