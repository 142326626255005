// 样式转换器
const formStampToDate = (row, column, cellValue) => {
    if (!cellValue) return "";
    let date;
    // 检查是否为时间戳（假设时间戳是以毫秒为单位）
    if (typeof cellValue === 'number') {
        date = new Date(cellValue);
    } else if (typeof cellValue === 'string') {
        // 如果是字符串，尝试将其转换为数字
        const timestamp = parseInt(cellValue, 10);
        if (!isNaN(timestamp)) {
            date = new Date(timestamp);
        } else {
            // 如果不是有效的时间戳字符串，尝试直接解析为日期
            date = new Date(cellValue);
        }
    } else {
        return "";
    }
    // 检查日期是否有效
    if (isNaN(date.getTime())) {
        return "无效日期";
    }
    return new Intl.DateTimeFormat("zh-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }).format(date);
};
// 样式转换器
const formatDate = (row, column, cellValue) => {
    if (!cellValue || cellValue === '' || cellValue === null || cellValue === undefined || cellValue === "0001-01-01T00:00:00") {
        return "-";
    }
    const date = new Date(cellValue);
    return new Intl.DateTimeFormat("zh-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }).format(date);
};
const getDaysUntilExpire = (expireDate) => {
    const now = new Date();
    const date = new Date(expireDate);
    const timeDiff = date - now;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff > 0 ? `生效中·${daysDiff}天后过期` : "已过期";
};
const getTagType = (expireDate) => {
    const now = new Date();
    const date = new Date(expireDate);
    return date > now ? "success" : "danger";
};
const ssltypeToTag = (ssltype) => {
    switch (ssltype) {
        case '单域名':
            return 'primary';
        case '多域名':
            return 'warning';
        case '泛域名':
            return 'success';
        default:
            return 'info';
    }
};
const fromBoolean = (value) => {
    return value ? "已启用" : "已关闭";
};
const fromservicePayEnumType = (row, column, cellValue) => {
    switch (cellValue) {
        case 0:
            return '资源点';
        case 1:
            return '包年服务';
        default:
            return '商品';
    }
};
const frompermission = (value) => {
    return value === 20 ? "权限开通" : "权限关闭";
};
const frompermissionTheme = (value) => {
    return value === 20 ? "success" : "danger";
};
const fromNodeStatus = (value) => {
    if (value.certId === "0") {
        return "未配置证书";
    } else if (value.count === 0) {
        return "证书已配置";
    }
    else {
        return value.operationStatus === true ? "部署成功" : "部署失败";
    }
};
const fromNodeResultStatus = (value) => {
    return value ? "部署成功" : "部署失败";
};
const getBooleanTagTepe = (value) => {
    return value ? "success" : "danger";
};
const fromCourseState = (value) => {
    return value === 20 ? "已上架" : "未上架";
};
const fromCourseAuditing = (value) => {
    switch (value) {
        case 10:
            return "审核中"
        case 20:
            return "审核通过"
        case 30:
            return "审核驳回"
        default:
            return "暂停待改";
    }
};
const fromCourseAssoModuleType = (value) => {
    switch (value) {
        case 10:
            return "目录视频";
        case 20:
            return "目录资料";
        case 30:
            return "课程首页图";
        case 40:
            return "课程宣传视频";
        case 50:
            return "课程详情图片";
        default:
            return "未知状态";
    }
};
const fromCourseVideoState = (value) => {
    switch (value) {
        case 5:
            return "无需审核";
        case 10:
            return "审核中";
        case 20:
            return "审核通过";
        case 30:
            return "审核驳回";
        default:
            return "-";
    }
};
const getStrTagTepe = (value) => {
    return value ? value : '暂未配置';
};

const formatBytes = (bytes) => {
    if (!bytes || bytes === 0) return '-';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
const formatCurrency = (amount) => {
    if (amount >= 10000) {
        return (amount / 10000).toFixed(2) + ' 万元';
    } else {
        return amount.toFixed(2) + ' 元';
    }
};
const formatDuration = (duration) => {
    if (!duration || duration === 0 || duration === '0' || duration === '00:00:00') return '-';
    // 分割天数和时间部分
    const parts = duration.split('.');
    if (parts.length <= 2) {
        const timePart = parts[0];
        // 分割小时、分钟、秒和毫秒部分
        const [hours, minutes, seconds] = timePart.split(':').map((part, index) => {
            if (index === 3) {
                return part.padEnd(6, '0');
            }
            return part.padStart(2, '0');
        });
        return `${hours}:${minutes}:${seconds}`; // 去掉毫秒部分
    }
    const days = parseInt(parts[0], 10);
    const timePart = parts[1];
    // 分割小时、分钟、秒和毫秒部分
    const [hours, minutes, seconds] = timePart.split(':').map((part, index) => {
        if (index === 3) {
            return part.padEnd(6, '0');
        }
        return part.padStart(2, '0');
    });
    // 计算总小时数
    const totalHours = days * 24 + parseInt(hours, 10);
    return `${totalHours}:${minutes}:${seconds}`; // 去掉毫秒部分
};
export default {
    fromservicePayEnumType,
    formStampToDate, fromNodeStatus, fromNodeResultStatus, ssltypeToTag,
    formatDate, getDaysUntilExpire, getTagType, fromBoolean, getBooleanTagTepe, frompermissionTheme,
    getStrTagTepe, formatBytes, formatCurrency, formatDuration, frompermission, fromCourseState, fromCourseAuditing, fromCourseAssoModuleType, fromCourseVideoState
}

