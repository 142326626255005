<template>
  <el-container class="page-container">
    <el-header>
      <HeaderPage />
    </el-header>
    <el-container class="body-container">
      <router-view></router-view>
    </el-container>
    <el-footer>
      <FooterPage />
    </el-footer>
  </el-container>
</template>

<script>
import HeaderPage from "./Container/Header.vue";
import FooterPage from "./Container/Footer.vue";

export default {
  name: 'IndexPage',
  components: { HeaderPage, FooterPage },
  props: {
    msg: String
  }
}
</script>
<style scoped>
.page-container,
.body-container {
  display: flex;
  overflow: auto;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ecf0f3;
}

.el-header,
.el-footer {
  background-color: #ecf0f3;
  color: #000000;
  height: 40px;
  align-content: center;
}
</style>