import { handleRequest, instance, Rsa } from './commonutil';
//用户登录
const signin = async (selectLogionMethod, loginFrom) => {
    let httpRequest;
    if (selectLogionMethod === '密码登录') {
        // 创建一个新的 loginForm 对象，包含加密后的密码
        const loginFormEncrypted = {
            userPhone: loginFrom.userPhone,
            userPassword: Rsa.rsaPublicData(loginFrom.userPassword)
        };
        httpRequest = () => instance.post('/api/User/AccountLogion', loginFormEncrypted);
    }
    else {
        httpRequest = () => instance.get(`/api/User/PhoneLogion?userPhone=${loginFrom.userPhone}&code=${Rsa.rsaPublicData(loginFrom.validateCode)}`);
    }
    return await handleRequest(httpRequest, '登录请求中...');
}
//用户注册
const signup = async (registerFrom) => {
    const resgisterDto = {
        userNickName: registerFrom.userNickName,
        userPhone: registerFrom.userPhone,
        userPassword: Rsa.rsaPublicData(registerFrom.userPassword),
        code: Rsa.rsaPublicData(registerFrom.validateCode)
    };
    let httpRequest = () => instance.post(`/api/User/Register`, resgisterDto);
    return await handleRequest(httpRequest, '注册请求中...');
};
//发送验证码
const sendValidateCode = async (userPhone) => {
    let httpRequest = () => instance.get(`/api/User/SendCode?userPhone=${userPhone}`);
    return await handleRequest(httpRequest, '发送电话验证码中...');
};
//邮箱验证码
const sendValidateEmailCode = async (email) => {
    let httpRequest = () => instance.get(`/api/User/SendEmailCode?email=${email}`);
    return await handleRequest(httpRequest, '发送邮箱验证码...');
};
//配置邮箱
const settingEmail = async (tempEmailForm) => {
    let httpRequest = () => instance.get(`/api/User/BindingEmail?email=${tempEmailForm.email}&code=${Rsa.rsaPublicData(tempEmailForm.code)}`);
    return await handleRequest(httpRequest, '绑定邮箱中...');
}
//用户上传头像
const settingAvatar = async (fileObj) => {
    const formData = new FormData();
    formData.append('formFile', fileObj.file); // 将文件添加到FormData对象中
    let httpRequest = () => instance.put('/api/User/SettingUserHeaderImg', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    return await handleRequest(httpRequest, '上传头像中...');
};
//用户设置昵称
const settingUserNickName = async (userNickName) => {
    const params = {
        userNickName: userNickName,
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.put(`/api/User/SettingUserNickName?${queryString}`);
    return await handleRequest(httpRequest, '更改用户昵称中...');
};
//用户密码重置
const settingPassword = async (userphone, tempPhoneForm) => {
    const params = {
        userPhone: userphone,
        userPassword: Rsa.rsaPublicData(tempPhoneForm.password),
        code: Rsa.rsaPublicData(tempPhoneForm.code)
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.put(`/api/User/SettingUserPassword?${queryString}`);
    return await handleRequest(httpRequest, '密钥重置中...');
}

const getSystemInfo = async () => {
    let httpRequest = () => instance.get('/api/User/GetSystemInfo');
    return await handleRequest(httpRequest, '获取活跃信息中...');
}
export default {settingUserNickName,
    signin, signup, sendValidateCode, sendValidateEmailCode, settingEmail, settingAvatar, settingPassword, getSystemInfo
};