import axios from "axios";
import store from "../stores/store.js";
import { ElLoading, ElMessage } from "element-plus";
import Rsa from "../utils/rsa.js";
import config from "@/assets/config.json";
const { baseURL } = config;

// http实例
const instance = axios.create({
    baseURL: baseURL,
    // timeout: 120000,
});

// http拦截
instance.interceptors.request.use(
    (config) => {
        const token = store.state.token;
        if (token) {
            config.headers["Authorization"] = token;
        }
        const isAbsoluteUrl = /^(https?:\/\/)/i.test(config.url);
        if (isAbsoluteUrl) {
            const parsedUrl = new URL(config.url);
            parsedUrl.host = new URL(baseURL).host;
            config.url = parsedUrl.toString();
        }
        return config;
    },
    (error) => Promise.reject(error)
);

const handleRequest = async (requestFn, loadingText = '加载中...') => {
    const loadingInstance = ElLoading.service({
        lock: true,
        text: loadingText,
        background: "rgba(0, 0, 0, 0.7)",
    });

    try {
        const response = await requestFn();
        if (response.data.code === 20000) {
            return response.data.data;
        }
        else {
            ElMessage.warning({
                message: response.data.message,
                duration: 800
            });
        }
    } catch (error) {
        ElMessage.error({
            message: error.message,
            duration: 800
        });
    } finally {
        loadingInstance.close();
    }
};
const yitIdhandleRequest = async (requestFn, loadingText = '加载中...') => {
    const loadingInstance = ElLoading.service({
        lock: true,
        text: loadingText,
        background: "rgba(0, 0, 0, 0.7)",
    });

    try {
        const response = await requestFn();
        if (response.data.toString()>=15) {
            return response.data;
        }
        else {
            ElMessage.warning({
                message: response.data.message,
                duration: 800
            });
        }
    } catch (error) {
        ElMessage.error({
            message: error.message,
            duration: 800
        });
    } finally {
        loadingInstance.close();
    }
};
export { handleRequest, yitIdhandleRequest,instance, Rsa, ElLoading, ElMessage };