import { handleRequest, instance } from './commonutil';
const getAccountPoints = async () => {
    let httpRequest = () => instance.get(`/api/Pay/GetAccountPoints`);
    return await handleRequest(httpRequest, '获取账户资源点...');
}
const getAccountSubs = async (pager) => {
    const params = {
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.get(`/api/Pay/GetAccountSubs?${queryString}`);
    return await handleRequest(httpRequest, '获取账户订阅服务...');
}
const getAccountPaymentHistorys = async (pager) => {
    const params = {
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.get(`/api/Pay/GetAccountPaymentHistorys?${queryString}`);
    return await handleRequest(httpRequest, '获取账户充值历史记录...');
}
const getAccountConsumptionHistorys = async (pager) => {
    const params = {
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.get(`/api/Pay/GetAccountConsumptionHistorys?${queryString}`);
    return await handleRequest(httpRequest, '获取账户消费历史记录...');
}
const getSystemServices = async (pager) => {
    const params = {
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.get(`/api/Pay/GetSystemServices?${queryString}`);
    return await handleRequest(httpRequest, '获取系统服务...');
}
const createPaymentService = async (data) => {
    const systemServiceDTO = {
        id: 0,
        isDeleted: false,
        createTimeStamp: 0,
        serviceName: data.serviceName,
        serviceIcon: data.serviceIcon,
        serviceProductName: data.serviceProductName,
        description: data.description,
        servicePayEnumType: data.servicePayEnumType,
        moneyAmount: data.moneyAmount,
        pointCount: data.pointCount,
    };
    let httpRequest = () => instance.post(`/api/Pay/CreatePaymentService`, systemServiceDTO);
    return await handleRequest(httpRequest, '创建系统服务...');
}
const updatePaymentService = async (data) => {
    let httpRequest = () => instance.put(`/api/Pay/UpdatePaymentService`, data);
    return await handleRequest(httpRequest, '更新系统服务...');
}
const deletePaymentService = async (id) => {
    let httpRequest = () => instance.delete(`/api/Pay/DeletePaymentService?serviceId=${id}`);
    return await handleRequest(httpRequest, '删除系统服务...');
}
const productPayQrCodePaymentSub = async (serviceId) => {
    const params = {
        serviceId: serviceId,
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.get(`/api/Pay/ProductPayQrCodePaymentSub?${queryString}`);
    return await handleRequest(httpRequest, '构建订阅服务订单...');
}
const productQrCodePaymentPoint = async (moneyAmount) => {
    const params = {
        moneyAmount: moneyAmount,
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.get(`/api/Pay/ProductQrCodePaymentPoint?${queryString}`);
    return await handleRequest(httpRequest, '构建资源点支付订单...');
}
const manuleProductQrCodePaymentPoint = async (userId, giftPoints) => {
    const params = {
        userId: userId,
        giftPoints: giftPoints
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.post(`/api/Pay/ManuleProductQrCodePaymentPoint?${queryString}`);
    return await handleRequest(httpRequest, '官方赠送用户资源点...');
}
const getUserInviteCode = async () => {
    let httpRequest = () => instance.get(`/api/Pay/GetUserInviteCode`);
    return await handleRequest(httpRequest, '获取邀请码...');
}
const acceptOtherCode = async (otherCodeKey) => {
    const params = {
        otherCodeKey: otherCodeKey
    };
    const queryString = new URLSearchParams(params).toString();
    let httpRequest = () => instance.get(`/api/Pay/AcceptOtherCode?${queryString}`);
    return await handleRequest(httpRequest, '认可邀请码...');
}
export default {getUserInviteCode,acceptOtherCode,
    getAccountPoints, getAccountSubs, getAccountPaymentHistorys, getAccountConsumptionHistorys, getSystemServices,
    createPaymentService, updatePaymentService, deletePaymentService, productPayQrCodePaymentSub, productQrCodePaymentPoint, manuleProductQrCodePaymentPoint
}