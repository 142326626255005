import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIcon from '@element-plus/icons-vue'
// 引入element-plus中文包
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import router from './routers/router'
import store from './stores/store'
import Rsa from "./utils/rsa"
import convertUtil from "./utils/convertUtil"
import certaxiosutil from './utils/certaxiosutil'
import userutil from "./utils/userutil"
import localstoragehelper from "./utils/localstoragehelper"
import rolepermissionutil from "./utils/rolepermissionutil"
import courseutil from './utils/courseutil'
import payutil from './utils/payutil'
import mitt from 'mitt'

import useClipboard from 'vue-clipboard3'



// // 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
// 全局捕获未处理的 Promise 拒绝
window.addEventListener("unhandledrejection", (event) => {
    event.preventDefault(); // 阻止默认行为（如弹窗）
});

const app = createApp(App);

app.use(ElementPlus, {
    locale: zhCn,
});
app.use(store);
app.use(router);
app.config.globalProperties.$emitter = mitt();
app.config.globalProperties.$rsa = Rsa;
app.config.globalProperties.$convertUtil = convertUtil;
app.config.globalProperties.$certaxiosutil = certaxiosutil;
app.config.globalProperties.$userutil = userutil;
app.config.globalProperties.$localstoragehelper = localstoragehelper;
app.config.globalProperties.$rolepermissionutil = rolepermissionutil;
app.config.globalProperties.$courseutil = courseutil;
app.config.globalProperties.$payutil = payutil;
const { toClipboard } = useClipboard();
app.config.globalProperties.$toClipboard = toClipboard;
// 注册所有图标
for (const [key, component] of Object.entries(ElIcon)) {
    app.component(key, component);
}
// Replace -> app.mount('#app')
// router.isReady().then(() => {
//     app.mount('#app')
// })

app.mount('#app')
