<template>
  <div class="header-container">
    <div class="left-menu">
      <el-image style="height: 35px" :src="require(`@/assets/lingyanspace.svg`)" />
      <span>灵燕空间</span>
      <ul class="centerTitleMenu">
        <li
          @click="navigateTo('默认首页')"
          :class="{ active: activeItem === '默认首页' }"
        >
          首页总览
        </li>
        <li @click="navigateTo('Cert')" :class="{ active: activeItem === 'Cert' }">
          证书服务
        </li>
        <li @click="navigateTo('Course')" :class="{ active: activeItem === 'Course' }">
          课堂服务
        </li>
        <li
          @click="navigateTo('Console')"
          :class="{ active: activeItem === 'Console' }"
        >
          账户控制台
        </li>
        <li @click="navigateTo('System')" :class="{ active: activeItem === 'System' }">
          系统管理
        </li>
      </ul>
    </div>
    <div class="right-menu">
      <ul class="right-menu-list">
        <li @click="navigateTo('Help')" :class="{ active: activeItem === 'Help' }">
          说明文档
        </li>
        <li @click="navigateTo('VideoStudy')" style="color: #007BFF; font-weight: bold">
          帮助视频
        </li>
        <li @click="navigateTo('BlogStudy')" style="color: #007BFF; font-weight: bold">
          图文博客
        </li>
        <li
          @click="navigateTo('OpenRepository')"
          style="color: #007BFF; font-weight: bold"
        >
          开源仓库
        </li>
      </ul>
      <el-dropdown :hide-on-click="false" style="margin-left: 10px">
        <span
          class="el-dropdown-link"
          style="display: flex; align-items: center; justify-content: center"
        >
          <el-avatar
            :size="35"
            style="margin-right: 10px"
            :src="user.userHeaderImg"
            shape="circle"
            >未</el-avatar
          >
          <template
            v-if="
              user.userPhone !== null &&
              user.userPhone !== undefined &&
              user.userPhone !== ''
            "
          >
            <span>{{ user.userNickName }}</span>
          </template>
          <template v-else>
            <span>未登录 ！</span>
          </template>
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="login">
              <el-icon> <InfoFilled /> </el-icon>前往登录
            </el-dropdown-item>
            <el-dropdown-item @click="logout">
              <el-icon> <SwitchButton /> </el-icon>注销用户
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  data() {
    return {
      activeItem: "", // 初始化为一个空字符串或其他默认值
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
    },
  },
  methods: {
    navigateTo(routeName) {
      switch (routeName) {
        case "OpenRepository":
          window.open("https://gitee.com/www-lingyanspace-com", "_blank"); // 在新标签页中打开
          break;
        case "VideoStudy":
          window.open("https://space.bilibili.com/237905456", "_blank"); // 在新标签页中打开
          break;
        case "BlogStudy":
          window.open("https://book.lingyanspace.com/books/854d6", "_blank"); // 在新标签页中打开
          break;
        default:
          this.$router.push({ name: routeName });
          this.$store.commit("NavigationModule", routeName);
          this.$store.commit("defaultEditTbale");
          this.activeItem = routeName;
          break;
      }
    },
    login() {
      //跳转到登录路由
      this.$router.push({ path: "/login" });
    },
    logout() {
      //清理数据
      this.$store.commit("resetState");
      //跳转到登录路由
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ecf0f3;
  padding: 0 20px;
}

.left-menu {
  display: flex;
  align-items: center;
}

.centerTitleMenu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  margin-left: 20px;
}

.centerTitleMenu li {
  position: relative;
  list-style: none;
  padding: 10px;
  margin-right: 20px;
  transition: all 0.2s linear;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.centerTitleMenu li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 3px;
  border-bottom: 3px solid #3b3b3b;
  transition: left 0.3s linear, width 0.3s linear;
}

.centerTitleMenu li:hover::before {
  left: 0%;
  width: 100%;
  border-bottom-color: #409eff;
}

.centerTitleMenu li:active {
  background: #2f4f4f;
  color: #fff;
}

.centerTitleMenu li.selected {
  color: blue;
}

.right-menu {
  display: flex;
  align-items: center;
}

.right-menu-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.right-menu-list li {
  position: relative;
  list-style: none;
  padding: 10px;
  margin-right: 20px;
  transition: all 0.2s linear;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.right-menu-list li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 3px;
  border-bottom: 3px solid #3b3b3b;
  transition: left 0.3s linear, width 0.3s linear;
}

.right-menu-list li:hover::before {
  left: 0%;
  width: 100%;
  border-bottom-color: #409eff;
}

.right-menu-list li:active {
  background: #2f4f4f;
  color: #fff;
}

.right-menu-list li.selected {
  color: blue;
}

.active {
  font-weight: bold;
}

.video-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-right: 30px;
}

:deep(.el-tooltip__trigger:focus-visible) {
  outline: unset;
}
</style>
