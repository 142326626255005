import { handleRequest, yitIdhandleRequest, instance, ElLoading, ElMessage } from './commonutil';

const getCourses = async () => {
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/Course/GetCourseStats`);
    return await handleRequest(httpRequest, '课程加载中...');
};
const getCourseStudents = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/Course/GetCourseStudents?${queryString}`);
    return await handleRequest(httpRequest, '学员加载中...');
};
const postOpenStudentAutho = async (courseStudentId) => {
    const params = {
        courseStudentId: courseStudentId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/OpenStudentAutho?${queryString}`);
    return await handleRequest(httpRequest, '开通学员权限中...');
};
const postCloseStudentAutho = async (courseStudentId) => {
    const params = {
        courseStudentId: courseStudentId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/CloseStudentAutho?${queryString}`);
    return await handleRequest(httpRequest, '关闭学员权限中...');
};
const deleteStudent = async (courseStudentId) => {
    const params = {
        courseStudentId: courseStudentId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.delete(`/api/Course/DeleteStudent?${queryString}`);
    return await handleRequest(httpRequest, '删除学员中...');
};
const postTeacherAddCourseStudent = async (courseId, studentPhone) => {
    const params = {
        courseId: courseId,
        studentPhone: studentPhone
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/TeacherAddCourseStudent?${queryString}`);
    return await handleRequest(httpRequest, '老师添加学员中...');
};

const postReleaseCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/ReleaseCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '课程发布中...');
}
const postOffShelfCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/OffShelfCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '课程下架中...');
}
const deleteCourse = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.delete(`/api/Course/DeleteCourse?${queryString}`);
    return await handleRequest(httpRequest, '课程删除中...');
}
//管理员系列
const postAdminGetCourseFullModels = async () => {
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminGetCourseFullModels`);
    return await handleRequest(httpRequest, '获取所有课程中...');
}
const postAdminGetCourseFileFullModels = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminGetCourseFileFullModels?${queryString}`);
    return await handleRequest(httpRequest, '获取课程文件中...');
}
const postAdminSuccessAuditingCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminSuccessAuditingCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '审核通过课程中...');
}
const postAdminFailAuditingCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminFailAuditingCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '审核驳回课程中...');
}
const postAdminSuccessCourseFile = async (courseFileId) => {
    const params = {
        courseFileId: courseFileId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminSuccessCourseFile?${queryString}`);
    return await handleRequest(httpRequest, '审核通过课程文件中...');
}
const postAdminFailCourseFile = async (courseFileId) => {
    const params = {
        courseFileId: courseFileId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminFailCourseFile?${queryString}`);
    return await handleRequest(httpRequest, '审核驳回课程文件中...');
}
const getInstallPackages = async () => {
    const httpRequest = () => instance.get(`/api/Soft/GetLastNewInstallPackageSoft`);
    return await handleRequest(httpRequest, '查询最新安装包中...');
}
const getAllCourseFullModels = async () => {
    const httpRequest = () => instance.get(`/api/Course/GetAllCourseFullModels`);
    return await handleRequest(httpRequest, '获取全部课程...');
}
const getPageListCourse = async (searchName, pager) => {
    const params = {
        searchName: searchName,
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    const httpRequest = () => instance.get(`/api/Course/GetPageListCourse?${queryString}`);
    return await handleRequest(httpRequest, '分页获取全部课程...');
}
const getListCourseCatalog = async (courseId) => {
    const params = {
        courseId: courseId,
    };
    const queryString = new URLSearchParams(params).toString();
    const httpRequest = () => instance.get(`/api/Course/GetListCourseCatalog?${queryString}`);
    return await handleRequest(httpRequest, '获取课程目录...');
}
const getCourseFull = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    const httpRequest = () => instance.get(`/api/Course/GetCourseFull?${queryString}`);
    return await handleRequest(httpRequest, '获取课程详情...');
}
const getSelfCourseFullModels = async () => {
    const httpRequest = () => instance.get(`/api/Course/getSelfCourseFullModels`);
    return await handleRequest(httpRequest, '用户获取订购课程...');
}

const StudentPayCourse = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    const httpRequest = () => instance.get(`/api/Course/StudentPayCourse?${queryString}`);
    return await handleRequest(httpRequest, '生成支付订单中...');
}
const getDownloadSettingFileName = async (url, fileName, courseId, loadingText = "下载文件中...") => {
    const loadingInstance = ElLoading.service({
        lock: true,
        text: loadingText,
        background: "rgba(0, 0, 0, 0.7)",
    });
    try {
        const config = {
            responseType: "blob",
            headers: {}, // 初始化 headers
        };
        config.headers["courseId"] = courseId; // 设置 CourseId
        let response = await instance.get(url, config);
        const { data, headers } = response;
        const blob = new Blob([data], { type: headers["content-type"] });
        const link = document.createElement("a");
        const downloadUrl = window.URL.createObjectURL(blob);
        link.href = downloadUrl;
        link.style.display = "none";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        ElMessage.error(error.message);
    } finally {
        loadingInstance.close();
    }
};

const getYitIdHelper = async () => {
    const httpRequest = () => instance.get(`/YitIdHelper/NextId`);
    return await yitIdhandleRequest(httpRequest, '获取分布式雪花Id...');
};
const uploadSingleAttachFile = async (fileObj, assoModuleType, courseId, catalogId) => {
    const formData = new FormData();
    formData.append('header', fileObj.file); // 将文件添加到FormData对象中 
    const params = {
        AssoModuleType: assoModuleType,
        courseId: courseId,
        catalogId: catalogId
    };
    const queryString = new URLSearchParams(params).toString();
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    let httpRequest = () => instance.post(`/api/Course/UploadSingleAttachFile?${queryString}`, formData, config);
    return await handleRequest(httpRequest, '上传课程文件中...');
};
const deleteSingleAttachFile=async(courseFileId,courseId,catalogId)=>{
    const params = {
        courseFileId: courseFileId,
        courseId: courseId,
        catalogId: catalogId
    };
    const queryString = new URLSearchParams(params).toString();
    const httpRequest = () => instance.post(`/api/Course/DeleteSingleAttachFile?${queryString}`);
    return await handleRequest(httpRequest, '删除课程文件中...');

};
const createCourse=async(createCourseModel,totalTime,totalSpace)=>{
    const params = {
        totalTime: totalTime,
        totalSpace: totalSpace,
    };
    const queryString = new URLSearchParams(params).toString();
    const httpRequest = () => instance.post(`/api/Course/CreateCourse?${queryString}`,createCourseModel);
    return await handleRequest(httpRequest, '创建课程中...');
};
const modifyCourse=async(createCourseModel,totalTime,totalSpace)=>{
    const params = {
        totalTime: totalTime,
        totalSpace: totalSpace,
    };
    const queryString = new URLSearchParams(params).toString();
    const httpRequest = () => instance.post(`/api/Course/ModifyCourse?${queryString}`,createCourseModel);
    return await handleRequest(httpRequest, '修改课程中...');
};
export default {
    getYitIdHelper,uploadSingleAttachFile,deleteSingleAttachFile,createCourse,modifyCourse,
    getSelfCourseFullModels,
    getAllCourseFullModels, getCourseFull, getPageListCourse, getListCourseCatalog, StudentPayCourse, getDownloadSettingFileName,
    getCourses, getCourseStudents, postOpenStudentAutho, postCloseStudentAutho, deleteStudent,
    postTeacherAddCourseStudent, postReleaseCourseFullModel, postOffShelfCourseFullModel, deleteCourse,
    postAdminGetCourseFullModels, postAdminGetCourseFileFullModels, postAdminSuccessAuditingCourseFullModel, postAdminFailAuditingCourseFullModel,
    postAdminSuccessCourseFile, postAdminFailCourseFile, getInstallPackages
};